import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { deleteRequestOptions, getAdminBaseApi, getImageUri, getRequestOptions, patchRequestOptions, putRequestOptions } from "../../utils/common";
import IdentityContext, { IdentityState } from '../auth/IdentityContext';
import { Alert, FormControlLabel, MenuItem, Select, Snackbar, Switch } from "@mui/material";
import CountInput from "../widgets/CountInput";
import Price from "../widgets/Price";
import EditablePriceField from "../widgets/EditablePriceField";
import { useHistory } from 'react-router-dom';

function Inventory (props) {
    const defaultItem = {
    id: '',
    name: '',
    description: '',
    categoryId: '1',
    brand: '',
    price: -1,
    cost: -1,
    supplierUrls: [],
    distributorUrl: '',
    homeUrls: [],
    externalId: {},
    inventory: {},
    imageId: '',
    codes: {
        upc: '',
        sku: ''
    },
    group: {
        enabled: false,
        groupId: '',
        variantName: '',
        imageUrl: ''
    },
    flags: {
      discontinued: false,
      hidden: false,
      special: false
    }
  }

  const history = useHistory();

  const [item, setItem] = useState(defaultItem);
  const [variants, setVariants] = useState([])
  const routeParams: any = useParams();
  const {identity, setIdentity} = useContext(IdentityContext);

  const itemId = routeParams.id ? routeParams.id : '';
  const dbname = routeParams.dbname ? routeParams.dbname : props.dbname;

  const [snackbarOpen, setSnackbarOpen] = useState<string|undefined>(undefined);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<string|undefined>(undefined);

  const [isParent, setIsParent] = useState(false)
  const [isChild, setIsChild] = useState(false)
  const [parentBox, setParentBox] = useState(false)
  const [debug, setDebug] = useState(false)
  const [id, setID] = useState('')
  const [name, setName] = useState('')
  const [desc, setDesc] = useState('')
  const [brand, setBrand] = useState('')
  const [categoryId, setCategoryId] = useState('1')
  const [sku, setSKU] = useState('')
  const [price, setPrice] = useState(-1)
  const [cost, setCost] = useState<number>(-1)
  const [upc, setUPC] = useState('')
  const [suppURL, setSuppURL] = useState('')
  const [mannURL, setMannURL] = useState('')
  const [imgurl, setImgurl] = useState('')
  const [imageId, setImgId] = useState('')
  const [hidden, setHidden] = useState(item.flags.hidden ? item.flags.hidden : false)
  const [discontinued, setDiscontinued] = useState(item.flags.discontinued ? item.flags.discontinued : false)
  const [special, setSpecial] = useState(item.flags.special ? item.flags.special : false)
  const [tempImgUrl, setTempImgUrl] = useState('')
  const [tempVarName, setTempVarName] = useState('')
  const [tempParentID, setTempParentID] = useState('')
  const [categories, setCategories] = useState([])

  const [inventory, setInventory] = useState<Record<string, InventoryItem>>({});
  const [inventoryUpdated, setInventoryUpdated] = useState<boolean>(false);
  const [childrenUpdated, setChildrenUpdated] = useState(false);

  const debugAlert = (msg) => {
    if (debug) {
      alert(msg)
    }
  }

  const getItem = async (itemID) => {
    if (!itemID) {
      return;
    }
    const requestOptions = getRequestOptions(identity.accessToken);
    console.log('getting item:' + itemID)
    await fetch(`${getAdminBaseApi(dbname)}/items/${itemID}` , requestOptions)
      .then(response => response.json())
      .then(json => {
        setItem(json);
        // console.log("ITEM: " + JSON.stringify(json, null, 1));
      })
      .catch(error => {
        console.error(`Error fetching item [${itemID}]: ${JSON.stringify(error)}`);
        setErrorSnackbarOpen(`Error fetching item [${itemID}]: ${JSON.stringify(error)}`);
      });
  }

  useEffect(() => {
    if (itemId !== ''){
      // api call for item data
      getItem(itemId)
    }
    else{
      setParentBox(false)
    }
  }, [itemId])

  useEffect(() => {
    // get the variants of an item on updating ID
    if (isParent){
      setParentBox(isParent)
      const requestOptions = getRequestOptions(identity.accessToken)
      fetch(`${getAdminBaseApi(dbname)}/items/${itemId}/variants` , requestOptions)
      .then(response => response.json())
        .then(json => {
          setVariants(json);
          // console.log("ITEM: " + JSON.stringify(json, null, 1));
        })
        .catch(error => {
          console.error(`Error fetching variants of [${itemId}]: ${JSON.stringify(error)}`);
          setErrorSnackbarOpen(`Error fetching variants of [${itemId}]: ${JSON.stringify(error)}`);
        });
    }
  }, [isParent])

  /**
   * Fetch the categories once at the beginning. Filter out to only contain the ones
   * that matter.
   */
  useEffect(() => {
    const requestOptions = getRequestOptions(identity.accessToken);
    const lookup: Record<string, any> = {};
    const children: Record<string, any[]> = {};

    const getCategoryPath = (cat?: any): string => {
      if (!cat) {
        throw new Error("Disconnected");
      }
      switch(cat.name) {
        case "error":
        case "Delete":
          throw new Error("Deleted");
      }
      if (cat.parentId <= 1) {
        return cat.name;
      }
      const parent = lookup[cat.parentId];
      return `${getCategoryPath(parent)} > ${cat.name}`;
    }

    fetch(`${getAdminBaseApi(dbname)}/categories` , requestOptions)
      .then(response => response.json())
      .then(json => {
        for (let i = 0; i < json.length; i++) {
          const cat = json[i];
          if (cat.hidden) {
            continue;
          }
          lookup[cat.id] = cat;

          if (!children[cat.parentId]) {
            children[cat.parentId] = [];
          }
          children[cat.parentId].push(cat);
        }

        let leaves = [];
        for (let i = 0; i < json.length; i++) {
          const cat = json[i];

          // if (cat.id === "8i1inhfJn2vP3K35zKpGmH") {
          //   console.log("ROOT: " + cat.id + ": " + JSON.stringify(cat));
          // }
          // if (cat.parentId === "8i1inhfJn2vP3K35zKpGmH") {
          //   console.log("CHILD: " + cat.id + ": " + lookup[cat.parentId]);
          // }

          try {
            cat.path = getCategoryPath(cat);
            if (debug) {
              console.log("  PATH: " + cat.path);
            }
            if (!children[cat.id]) {
              leaves.push(cat);
            }
            else {
            }
          }
          catch (err) {
            // Console error, these WILL happen
            if (debug) {
              console.error(err);
            }
          }
        }
        leaves = leaves.sort((a, b) => {
          return a.path.localeCompare(b.path);
        });
        setCategories(leaves);
      })
      .catch(error => {
        console.error(error);
        setErrorSnackbarOpen(error);
      });
  }, [])

  // populate the form fields with item data
  useEffect(() => {
    setID(item.id ? item.id : itemId)
    setName(item.name ? item.name : '')
    setUPC(item.codes?.upc ? item.codes.upc : '')
    setSKU(item.codes?.sku ? item.codes.sku : '')
    setBrand(item.brand ? item.brand : '')
    setDesc(item.description ? item.description : '')
    setPrice(item.price ? item.price : -1)
    setCost(item.cost ? item.cost : -1)
    // setImgId(item.imageId ? item.imageId : '')
    setCategoryId(item.categoryId ? item.categoryId : '')
    setImgurl(item.imageId === undefined? '' : getImageUri(item.imageId, dbname));
    setInventory(item.inventory ? item.inventory : {});
    setSuppURL(item.supplierUrls ? item.supplierUrls[0] : '')
    setHidden(item.flags.hidden ? item.flags.hidden : false)
    setDiscontinued(item.flags.discontinued ? item.flags.discontinued : false)
    setSpecial(item.flags.special ? item.flags.special : false)
    setIsParent(item.group?.enabled && (item.group?.groupId === '') ? true : false)
    setIsChild(item.group?.enabled && !(item.group?.groupId === '') ? true : false)
    setTempParentID('')
    // We have to get fancy looking at manufacturer URL, cause it is complicated
    var murl = item.distributorUrl
    if (!murl && item.homeUrls && item.homeUrls[0]) {
      murl = item.homeUrls[0];
    }
    if (!murl) {
      murl = '';
    }
    setMannURL(murl)

  }, [item])

  useEffect(() => {
    debugAlert(`imgurl: ${imgurl}`)
  }, [imgurl])

  // checks response from API call to see
  const popup = async (code, resp, closeMe: boolean = false) => {
    console.log("POPUP: 1 [" + code + "] " + JSON.stringify(resp));
    debugAlert(`popup begin code: ${code}`)
    const stringResp = JSON.stringify(resp)
    if (resp.name === 'MongoError'){
      debugAlert('mongoError')
      var errorValue = JSON.stringify(resp.keyValue)
      if (errorValue.includes('name')){
        setErrorSnackbarOpen('that name is taken')
      }
      else if (errorValue.includes('upc')){
        setErrorSnackbarOpen('that UPC is already in use')
      }
      else{
        setErrorSnackbarOpen(stringResp)
      }
      return
    }
    else if (stringResp === "\"Success\""){
      console.log("POPUP: 2" + JSON.stringify(resp));
      debugAlert('success popup resp')
      setSnackbarOpen("Successfully updated");
      if (closeMe) {
        setTimeout(function(){window.close();}, 3000);
      }
      else {
        // Refresh the item data
        window.location.reload()
      }
      return
    }
    else if (stringResp === "\"Operation Failed\""){
      alert(stringResp)
    }
    else if (stringResp === "\"Something went wrong\""){
      alert(stringResp)
    }
    else {
      console.log("POPUP: 3" + JSON.stringify(resp));
      // We get in here on create, but also on some update options (eg image upload).
      if (resp.id) {
        debugAlert('popup else')
        // resp.id is only set on item creation
        if (tempImgUrl !== '') {
          const newImgUrl = tempImgUrl;
          setTempImgUrl(""); // FIXME: Reset after successful submission
          await submitImage(resp.id, newImgUrl);
        }
        debugAlert('popupelse gets item')
        getItem(resp.id)
        // alert('popup itam:' + JSON.stringify(item))
        setSnackbarOpen("Item Created")
        const path = window.location.pathname;
        const parts = path.split("/");
        const newUrl = `/admin/${parts[2]}/item/${resp.id}`
        history.push(newUrl);
      }
      else {
        switch(code) {
          case 200:
          case 201:
            setSnackbarOpen("Successfully updated");
            break;
          default:
            setErrorSnackbarOpen(`ERROR: Return code ${code}`);
            break;
        }
        await getItem(itemId);
      }
      if (closeMe) {
        setTimeout(function(){window.close();}, 3000);
      }
    }
    debugAlert('end of popup')
    debugAlert(stringResp)
  }

  // checks response from API call for child creation
  const popupChildAdd = async (code, resp, closeMe: boolean = false) => {
    console.log("POPUP: 1 [" + code + "] " + JSON.stringify(resp));
    debugAlert(`popup CHILD begin code: ${code}`)
    const stringResp = JSON.stringify(resp)
    if (resp.name === 'MongoError'){
      debugAlert('mongoError')
      var errorValue = JSON.stringify(resp.keyValue)
      if (errorValue.includes('name')){
        setErrorSnackbarOpen('that name is taken')
      }
      else if (errorValue.includes('upc')){
        setErrorSnackbarOpen('that UPC is already in use')
      }
      else{
        setErrorSnackbarOpen(stringResp)
      }
      return
    }
    else if (stringResp === "\"Success\""){
      console.log("POPUP: 2" + JSON.stringify(resp));
      debugAlert('success popup resp')
      setSnackbarOpen("Successfully updated");
      if (closeMe) {
        setTimeout(function(){window.close();}, 3000);
      }
      else {
        // Refresh the item data
        window.location.reload()
      }
      return
    }
    else if (stringResp === "\"Operation Failed\""){
      alert(stringResp)
    }
    else if (stringResp === "\"Something went wrong\""){
      alert(stringResp)
    }
    else {
      console.log("POPUP: 3" + JSON.stringify(resp));
      // We get in here on create, but also on some update options (eg image upload).
        switch(code) {
          case 200:
          case 201:
            setSnackbarOpen("Successfully updated");
            break;
          default:
            setErrorSnackbarOpen(`ERROR: Return code ${code}`);
            break;
        }
        await getItem(itemId);
      if (closeMe) {
        setTimeout(function(){window.close();}, 3000);
      }
    }
    debugAlert('end of popup')
    debugAlert(stringResp)
    window.location.reload()
  }

  const alertItem = () => {
    alert(JSON.stringify(item))
  }

  const putItem = (temp) => {
    setItem(temp)
    const requestOptions = putRequestOptions(JSON.stringify({'item': temp}), identity.accessToken);

    // PUT request to create item
    fetch(`${getAdminBaseApi(dbname)}/items/`, requestOptions)
      .then(response => response.json())
      .then(json => {popup(201, json);}) // Hack -- assume it is 201 till I fix the logic
      .catch(error => {
        setErrorSnackbarOpen(error);
        console.error(error)
      });
  }
  
  const putChild = (temp) => {
    temp.group.groupId = itemId
    const requestOptions = putRequestOptions(JSON.stringify({'item': temp}), identity.accessToken);

    // PUT request to create item
    fetch(`${getAdminBaseApi(dbname)}/items/`, requestOptions)
      .then(response => response.json())
      .then(json => {popupChildAdd(201, json);}) // Hack -- assume it is 201 till I fix the logic
      .catch(error => {
        setErrorSnackbarOpen(error);
        console.error(error)
      });
  }

  const patchItem = async (field, data, closeMe: boolean = false) => {
    let patchBody = ''
    switch (field) {
      default:
        break
      case 'name':
        patchBody = JSON.stringify({'name': data})
        break
      case 'upc':
        patchBody = JSON.stringify({'upc': data})
        break
      case 'brand':
        patchBody = JSON.stringify({'brand': data})
        break
      case 'description':
        patchBody = JSON.stringify({'description': data})
        break
      case 'price':
        patchBody = JSON.stringify({'price': data})
        break
      case 'cost':
        patchBody = JSON.stringify({'cost': data}) 
        break
      case 'imageUrl':
        patchBody = JSON.stringify({'imageUrl': data})
        break
      case 'categoryId':
        patchBody = JSON.stringify({'categoryId': data}) 
        break
      case 'inventory':
        patchBody = JSON.stringify({'inventory': data}) 
        break
      case 'supplierUrl':
        patchBody = JSON.stringify({'supplierUrl': data})
        break
      case 'distributorUrl':
        patchBody = JSON.stringify({'distributorUrl': data})
        break
      case 'hidden':
        patchBody = JSON.stringify({'hidden': data})
        break
      case 'discontinued':
        patchBody = JSON.stringify({'discontinued': data})
        break
      case 'special':
        patchBody = JSON.stringify({'special': data})
        break
      case 'group.enabled':
        patchBody = JSON.stringify({'group.enabled': data})
        break
      case 'group.groupId':
        patchBody = JSON.stringify({'group.groupId': data})
        break
      case 'group.variantName':
        patchBody = JSON.stringify({'group.variantName': data})
        break
    }   
    if (patchBody === ''){
      alert('empty Body in patchItem')
      return
    }

    const requestOptions = patchRequestOptions(patchBody, identity.accessToken);

    // PUT request to create item
    let apiURL = `${getAdminBaseApi(dbname)}/items/${item.id}/${field}`
    debugAlert('api url: ' + apiURL)

    console.log("PATCHING: " + JSON.stringify(requestOptions, null, 1));

    try {
      // Fetch is asynchronous
      const response = await fetch(apiURL, requestOptions);
      console.log("PATCH COMPLETE");

      popup(response.status, response.json(), closeMe)
    }
    catch(error: any) {
      console.error(error)
      setErrorSnackbarOpen(error);
    }
  }

  const checkUpdate = async (temp, closeMe: boolean = false) => {
    debugAlert('inside update')
    if (temp.name !== name){
      await patchItem('name', name, closeMe)
    }
    // Ugly condition. If either the temp code or upc are non-empty, then we are
    // editing if they are different. We need this extra condition cause the temp
    // code can be "undefined" which is different from empty ("")
    if ((!!temp.codes?.upc) || (upc.trim() !== '') && (temp.codes?.upc !== upc.trim())) {
      if (upc.trim() === '') {
        // You cannot have an empty UPC, so set it to the id
        setUPC(itemId);
        await patchItem('upc', itemId, closeMe);
      }
      else {
        await patchItem('upc', upc.trim(), closeMe)
      }
    }
    if (temp.brand !== brand){
      await patchItem('brand', brand, closeMe)
    }
    if (temp.description !== desc){
      await patchItem('description', desc, closeMe)
    }
    if (temp.price !== price){
      await patchItem('price', price, closeMe)
    }
    if (temp.cost !== cost){
      await patchItem('cost', cost, closeMe)
    }
    if (temp.categoryId !== categoryId){
      await patchItem('categoryId', categoryId, closeMe)
    }
    // only index 0 is referenced until the entire array is supported (ETA: When pigs fly)
    if (temp.supplierUrls[0] !== suppURL){
      debugAlert('supp url')
      await patchItem('supplierUrl', suppURL, closeMe)
    }
    if (temp.distributorUrl !== mannURL){
      console.log("CHECKING: " + temp.distributorUrl + " !== " + mannURL);
      await patchItem('distributorUrl', mannURL, closeMe)
    }
    if (temp.flags.hidden !== hidden){
      await patchItem('hidden', hidden, closeMe)
    }
    if (temp.flags.discontinued !== discontinued){
      await patchItem('discontinued', discontinued, closeMe)
    }
    if (temp.flags.special !== special){
      await patchItem('special', special, closeMe)
    }
    if (temp.group.enabled !== parentBox){
      await patchItem('group.enabled', parentBox, closeMe)
    }
    if ((temp.group.groupId !== tempParentID) && (tempParentID !== '')){
      alert('patching groupID')
      await patchItem('group.enabled', true, closeMe)
      await patchItem('group.groupId', tempParentID, closeMe)
      await patchItem('group.variantName', 'variant', closeMe)
    }
    if (inventoryUpdated) {
      await patchItem('inventory', inventory, closeMe);
    }
    if (tempImgUrl !== '') {
      const newImgUrl = tempImgUrl;
      setTempImgUrl(""); // FIXME: Reset after successful submission
      await submitImage(temp.id, newImgUrl);
    }
    debugAlert('setting Item')
    setItem(temp)
  }

  // Handles the data when user presses submit
  const handleSubmit = async (closeMe: boolean = false) => {
    // store state in temp item to submit
    var tempItem = {...item}
    if (categoryId === '1'){
      alert('This item has no category, add one to create the item')
      return
    }
    tempItem.name = name
    tempItem.codes.upc = upc
    tempItem.categoryId = categoryId
    tempItem.brand = brand
    tempItem.description = desc
    tempItem.price = price
    tempItem.cost = cost
    tempItem.supplierUrls = [suppURL]
    tempItem.distributorUrl = mannURL
    tempItem.homeUrls = [mannURL]
    tempItem.inventory = inventory
    tempItem.group.enabled = parentBox
    debugAlert('making put')
    await putItem(tempItem)
  }

  // Handles the data when user duplicates an item
  const handleDuplicate = async (closeMe: boolean = false) => {
    // store state in temp item to submit
    var tempItem = {...item} // This makes sure tempItem is a duplicate
    tempItem.name = name + ' duplicate'
    tempItem.codes.upc = ''
    tempItem.categoryId = categoryId
    tempItem.brand = brand
    delete (tempItem as any).stock; // Hack to delete the old "stock" values.
    tempItem.description = desc
    tempItem.price = price
    tempItem.cost = cost
    tempItem.supplierUrls = [suppURL]
    tempItem.distributorUrl = mannURL
    tempItem.homeUrls = [mannURL]
    tempItem.inventory = {}
    // tempItem.flags.discontinued = discontinued
    // tempItem.flags.hidden = hidden
    // tempItem.flags.special = special
    debugAlert('duplicating item')
    if (window.confirm(`Do you want to save changes to "${name}" and duplicate it?`)) {
      await handleUpdate()
      await putItem(tempItem)
    }
  }
  
  // Handles the data when user presses submit
  const handleNewChild = async (closeMe: boolean = false) => {
    // store state in temp item to submit
    if (tempVarName === ''){
      alert('You must give your child a name')
      return
    }
    var tempItem = item
    tempItem.name = name + ' ' + tempVarName
    tempItem.codes.upc = ''
    tempItem.categoryId = categoryId
    tempItem.brand = brand
    delete (tempItem as any).stock; // Hack to delete the old "stock" values.
    tempItem.description = desc
    tempItem.price = price
    tempItem.cost = cost
    tempItem.supplierUrls = [suppURL]
    tempItem.distributorUrl = mannURL
    tempItem.homeUrls = [mannURL]
    tempItem.inventory = {}
    tempItem.group.variantName = tempVarName
    // tempItem.flags.discontinued = discontinued
    // tempItem.flags.hidden = hidden
    // tempItem.flags.special = special
    debugAlert('making child item')
    await putChild(tempItem)
  }

  /**
   * Image submission is run by a button click instead of the update
   */
  const submitImage = async (itemId: string, newImgUrl): Promise<void> => {
    debugAlert('start submit image')
    let patchBody = JSON.stringify({"imageUrl": newImgUrl})

    const requestOptions = patchRequestOptions(patchBody, identity.accessToken);

    // submit image address to server
    let apiURL = `${getAdminBaseApi(dbname)}/items/${itemId}/image`
    debugAlert('imgAPI: ' + apiURL)
    try {
      const response = await fetch(apiURL, requestOptions);
      const json = response.json;
      popup(response.status, json);
    }
    catch(error) {
        console.error(error);
        setErrorSnackbarOpen(error);
    };
  }
  
  // Handles the data when user updates item
  const handleUpdate = async (closeMe: boolean = false) => {
    if (isParent){
      if (childrenUpdated){
        // update inventory of children for parent items
        debugAlert('patchingChildren')
        variants.map( async (variant) => {
          const patchBody = JSON.stringify({'inventory': variant.inventory})
          debugAlert(JSON.stringify(variant.inventory))
          const requestOptions = patchRequestOptions(patchBody, identity.accessToken);

          // PUT request to create item
          let apiURL = `${getAdminBaseApi(dbname)}/items/${variant.id}/inventory`
          debugAlert('api url: ' + apiURL)

          try {
            // Fetch is asynchronous
            const response = await fetch(apiURL, requestOptions);
            console.log("PATCH COMPLETE");

            popup(response.status, response.json(), closeMe)
          }
          catch(error: any) {
            console.error(error)
            setErrorSnackbarOpen(error);
          }
        })
      }
    }
    var tempItem = item
    debugAlert('updating')
    await checkUpdate(tempItem, closeMe)
  }

  const deleteItem = () => {
    if (window.confirm(`Did you really mean to delete "${name}"?`)) {
      const requestOptions = deleteRequestOptions(identity.accessToken)
      let apiURL = `${getAdminBaseApi(dbname)}/items/${id}`
      fetch(apiURL, requestOptions)
        .catch(error => {
          console.error(error);
          setErrorSnackbarOpen(error);
        });
      setItem(defaultItem)
      setMannURL('')
      const path = window.location.pathname;
      const parts = path.split("/");
      const newUrl = `/admin/${parts[2]}/item`
      history.push(newUrl);
    }
  }

  /**
   * The specified stock value has been edited
   */
    const handleEditStock = (key: string, value: number) => {
      const iitem: InventoryItem = item.inventory[key] ? item.inventory[key] : {} as InventoryItem;
      iitem.count = value;
      const newInventory = {...inventory};
      newInventory[key] = iitem;
      setInventory(newInventory);
      setInventoryUpdated(true);
  }
  
  /**
   * The specified stock value of a child has been edited
   */
  const handleEditChildStock = (key: string, value: number, obj: any, index: number) => {
    debugAlert('edited child')
    var tempChild = obj
    var tempVariants = variants
    const iitem: InventoryItem = obj.inventory[key] ? obj.inventory[key] : {} as InventoryItem;
    iitem.count = value;
    const newInventory = {...obj.inventory};
    newInventory[key] = iitem;
    tempChild.inventory = newInventory;
    tempVariants[index] = tempChild;
    setVariants(tempVariants);
    setChildrenUpdated(true);
  }
  

  /**
   * Snackbar close
   */
  const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(undefined);
  };

  /**
   * Snackbar close
   */
  const handleErrorSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setErrorSnackbarOpen(undefined);
  };

  const ImageComponent = () => {
    if (itemId) {
      return (
        <>
          <div className="field">
            <p>Image URL</p>
            <div>{imgurl}</div>
          </div>
          <div className="field">
            <p>New Image URL</p>
            <input title="New Image URL" value={tempImgUrl} onChange={(e) => setTempImgUrl(e.target.value)}/> 
          </div>
        </>
      )
    }
    return (<></>)
  }

  // Render inventory component(s) depending on if the given itemID is the parent
  const InventoryComponent = () => {
    if (itemId && !isParent){
      return(
            <div className="field"> 
              <p>Inventory</p>
              <div className="inventory">
                <CountInput id="edit-stock" resetAt={item.inventory["liverpool"]?.resetAt} handleCommit={(value: number) => handleEditStock("liverpool", value)} label="Lvpl" initial={item.inventory["liverpool"] ? item.inventory["liverpool"]?.count : 0}/>
                <CountInput id="edit-stock" resetAt={item.inventory["bridgewater"]?.resetAt} handleCommit={(value: number) => handleEditStock("bridgewater", value)} label="Bwtr" initial={item.inventory["bridgewater"] ? item.inventory["bridgewater"].count : 0}/>
                <CountInput id="edit-stock" resetAt={item.inventory["warehouse"]?.resetAt} handleCommit={(value: number) => handleEditStock("warehouse", value)} label="Ware" initial={item.inventory["warehouse"] ? item.inventory["warehouse"].count : 0}/>
                <CountInput id="edit-stock" resetAt={item.inventory["bongbus"]?.resetAt} handleCommit={(value: number) => handleEditStock("bongbus", value)} label="Bus" initial={item.inventory["bongbus"] ? item.inventory["bongbus"].count : 0}/>
                <CountInput id="edit-stock" resetAt={item.inventory["overflow"]?.resetAt} handleCommit={(value: number) => handleEditStock("overflow", value)} label="Err" initial={item.inventory["overflow"] ? item.inventory["overflow"].count : 0}/>
              </div>
            </div>
      )
    }
    return(
      variants.map((variant: any, index) => {
        const path = window.location.pathname;
        const parts = path.split("/");
        const childUrl = `/admin/${parts[2]}/item/${variant.id}`
        return(
          <div className="field"> 
            <a href={childUrl}>{variant.name}</a>
            <div className="inventory">
              <CountInput id="edit-stock" resetAt={variant.inventory["liverpool"]?.resetAt} handleCommit={(value: number) => handleEditChildStock("liverpool", value, variant, index)} label="Lvpl" initial={variant.inventory["liverpool"] ? variant.inventory["liverpool"]?.count : 0}/>
              <CountInput id="edit-stock" resetAt={variant.inventory["bridgewater"]?.resetAt} handleCommit={(value: number) => handleEditChildStock("bridgewater", value, variant, index)} label="Bwtr" initial={variant.inventory["bridgewater"] ? variant.inventory["bridgewater"].count : 0}/>
              <CountInput id="edit-stock" resetAt={variant.inventory["warehouse"]?.resetAt} handleCommit={(value: number) => handleEditChildStock("warehouse", value, variant, index)} label="Ware" initial={variant.inventory["warehouse"] ? variant.inventory["warehouse"].count : 0}/>
              <CountInput id="edit-stock" resetAt={variant.inventory["bongbus"]?.resetAt} handleCommit={(value: number) => handleEditChildStock("bongbus", value, variant, index)} label="Bus" initial={variant.inventory["bongbus"] ? variant.inventory["bongbus"].count : 0}/>
              <CountInput id="edit-stock" resetAt={variant.inventory["overflow"]?.resetAt} handleCommit={(value: number) => handleEditChildStock("overflow", value, variant, index)} label="Err" initial={variant.inventory["overflow"] ? variant.inventory["overflow"].count : 0}/>
            </div>
          </div>
        )
      })
    )
  }

  // Return link to the parent if you are editing a child
  const ParentLink = () => {
    if (!isChild){
      return
    }
    const path = window.location.pathname;
    const parts = path.split("/");
    const parentUrl = `/admin/${parts[2]}/item/${item.group.groupId}`
    return(
      <a href={parentUrl}>{'Parent of this Item'}</a>
    )
  }
  
  return(
    <>
      <Snackbar open={!!snackbarOpen} autoHideDuration={6000} onClose={handleSnackbarClose} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
          {snackbarOpen}
        </Alert>
      </Snackbar>
      <Snackbar open={!!errorSnackbarOpen} autoHideDuration={6000} onClose={handleErrorSnackbarClose}>
        <Alert onClose={handleErrorSnackbarClose} severity="error" sx={{ width: '100%' }}>
          {errorSnackbarOpen}
        </Alert>
      </Snackbar>
      <div id="debug">
        {debug?
          <>
            <button onClick={() => alertItem()}>Alert Item</button>
            <button onClick={() => alert(JSON.stringify(categories))}>print cats</button>
            <button onClick={() => alert(imgurl)}>print imgurl</button>
          </>
        :
          <></>
        }
        <FormControlLabel control={<Switch />} label="Debug" checked={debug} onChange={() => setDebug(!debug)}/>
      </div>
      <div className="card">
        <img 
          src={imgurl}
          className="catImg"
          alt='' 
        />
        <div className="field-set">
          <div className="field">
            <p>ID {id}</p>
          </div>
          {ParentLink()}
          {!isParent && !isChild && !!itemId?
          <div className="field">
            <button onClick={() => handleDuplicate()}>
              Duplicate Item
            </button>

          </div>
          :
          <></>
          }
          <div className="field">
            <p>Name</p>
            <input value={name} onChange={(e) => setName(e.target.value)}/>
          </div>
          {isChild ?
          <></>
          :
          <div className="checkboxDiv">
            <div className="checkbox">
              <p>Parent:</p>
              <input 
                checked={parentBox} 
                type={"checkbox"}
                onChange={() => setParentBox(!parentBox)}
              />
            </div>
          </div>
          }
          {isParent ?
          <></>
          :
          <>
            <div className="field">
              <p>UPC</p>
              <input value={upc} onChange={(e) => setUPC(e.target.value)}/>
            </div>
            <div className="field">
              <p>SKU</p>
              <input disabled value={sku} onChange={(e) => setSKU(e.target.value)}/>
            </div>
          </>
          }
          <div className="field">
            <p>Brand</p>
            <input value={brand} onChange={(e) => setBrand(e.target.value)}/>
          </div>
          <div className="field">
              <p>Category</p>
              <Select
                id="category-select"
                className="select"
                value={categoryId}
                label="Category"
                onChange={(e) => setCategoryId(e.target.value)}>
                {
                  categories.map((cat: any, _) => {
                    return (
                    <MenuItem value={cat.id} key={cat.id}>{cat.path}</MenuItem>
                  )})
                }
              </Select>
          </div>
          <div className="field">
            <p>Description</p>
            <textarea value={desc} onChange={(e) => setDesc(e.target.value)}/>
          </div>
          {isParent ? 
          <></>
          :
          <>
            <div className="field">
              <p>Price</p>
              <EditablePriceField value={price} onCommit={(value) => setPrice(value)}></EditablePriceField>
            </div>
            <div className="field">
              <p>Cost</p>
              <EditablePriceField value={cost} onCommit={(value) => setCost(value)}></EditablePriceField>
            </div>
          </>
          } 
          <div className="field">
              <p>
                <a href={item.supplierUrls[0]} target="_blank">Supplier URL</a>
              </p>
              <input value={suppURL} onChange={(e) => setSuppURL(e.target.value)}/>
          </div>
          <div className="field">
            <p>Manufacturer URL</p>
            <input value={mannURL} onChange={(e) => setMannURL(e.target.value)} />
          </div>
          {ImageComponent()}
          {InventoryComponent()}
          {isParent?
          <div className="field">
            <p>Child Name:</p>
            <input className="variantName" value={tempVarName} onChange={(e) => setTempVarName(e.target.value)}/>
            <button onClick={() => handleNewChild()}>
              Add Child
            </button>
          </div>
          :
          <></>
          }
          {isParent ?
          <div className="checkboxDiv">
            <div className="checkbox">
              <p>Discontinued</p>
              <input 
                checked={discontinued} 
                type={"checkbox"}
                onChange={() => setDiscontinued(!discontinued)}
              />
            </div>
          </div>
          :
          <></>
          }
          {item.id === '' || isParent?
            <></>
          :
          <>
            <div className="checkboxDiv">
              <div className="checkbox">
                <p>Discontinued</p>
                <input 
                  checked={discontinued} 
                  type={"checkbox"}
                  onChange={() => setDiscontinued(!discontinued)}
                />
              </div>
              <div className="checkbox">
                <p>Hidden</p>
                <input 
                  checked={hidden} 
                  type={"checkbox"}
                  onChange={() => setHidden(!hidden)}
                />
              </div>
              <div className="checkbox">
                <p>Special</p>
                <input 
                  checked={special} 
                  type={"checkbox"}
                  onChange={() => setSpecial(!special)}
                />
              </div>
            </div>
            <div className="field">
              <p>Parent ID:</p>
              <input value={tempParentID} onChange={(e) => setTempParentID(e.target.value)}/>
            </div>
          </>
          }
          <div className="field buttons">
            {item.id === ''?
              <div>
                <button onClick={() => handleSubmit()}>Create Item</button>
                {/* <button onClick={() => handleSubmit(true)}>Create and close</button> */}
              </div>
            :
              <>
                <div className="deleteButton"
                  onClick={() => deleteItem()}
                >
                  DELETE ITEM
                </div>
                <div>
                  <button onClick={() => handleUpdate()}>Update Item</button>
                  {/* <button onClick={() => handleUpdate(true)}>Update and close</button> */}
                </div>
              </>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default Inventory
