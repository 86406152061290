

export const getRequestOptions = (accessToken?: string): any => {
    const requestOptions: any = {
        method: 'GET',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Accept-Encoding': 'gzip, defiate, br',
        }
    };

    if (accessToken) {
        requestOptions.headers['Authorization'] = 'Bearer ' + accessToken;
    }

    return requestOptions;
}

export const putRequestOptions = (json: string, accessToken?: string): any => {
    const requestOptions: any = {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Accept-Encoding': 'gzip, defiate, br',
        },
        body: json
    };

    if (accessToken) {
        requestOptions.headers['Authorization'] = 'Bearer ' + accessToken;
    }

    return requestOptions;
}

export const patchRequestOptions = (json: string, accessToken?: string): any => {
    const requestOptions: any = {
        method: 'PATCH',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Accept-Encoding': 'gzip, defiate, br',
        },
        body: json
    };

    if (accessToken) {
        requestOptions.headers['Authorization'] = 'Bearer ' + accessToken;
    }

    return requestOptions;
}

export const deleteRequestOptions = (accessToken?: string): any => {
    const requestOptions: any = {
        method: 'DELETE',
        headers: { 
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Accept-Encoding': 'gzip, defiate, br',
        }
    };

    if (accessToken) {
        requestOptions.headers['Authorization'] = 'Bearer ' + accessToken;
    }

    return requestOptions;
}


export const getAdminBaseApi = (database: string = "backroom"): string => {
    return `${process.env.REACT_APP_ADMIN_HOST}/${database}/api/${process.env.REACT_APP_ADMIN_VERSION}`;
}

export const getImageUri = (imageId: string, dbName: string = "backroom"): string => {
    let storeName = "The%20Back%20Room";

    switch(dbName) {
        case "nursery":
            storeName = "Florist";
            break;
        case "supplies":
            storeName = "Garden%20Centre";
            break;
        case "backroom":
            storeName = "The%20Back%20Room";
            break;
    }

    return `https://thequeensflorist.sirv.com/${storeName}/${imageId}.jpg`
}