import './Price.css';

type Props = {
    price?: number,
    showZero?: boolean,
    id?: string,
    showZeroAsBlank?: boolean
  }
  
  const Price = ({ price, showZero, id, showZeroAsBlank }: Props) => {
  
    const thePriceBase = price;
    const thePrice = thePriceBase && thePriceBase > 0 ? thePriceBase : 0;
    let thePriceString = `${thePrice}`;
    const index = thePriceString.indexOf('.');
    if (index > 0) {
      thePriceString = thePriceString.substring(0, index);
    }
    const dollars = thePrice ? Math.trunc(thePrice/100) : 0;
    
    const centsString1 = thePriceString.slice(-2);
    const cents = +centsString1;
  
    // const cents = thePrice ? thePrice - (dollars * 100) : 0;
    const centsString = cents < 10 ? "0" + cents : cents;
  
    if (dollars + cents <= 0 && showZeroAsBlank) {
      return (
        <></>
        )  
    }
    
    if (dollars + cents <= 0 && !showZero) {
      return (
        <>$<span className="price"><span className="dollars">X</span><span className="cents">XX</span></span></>
        )  
    }
  
    if (id) {
      return (
        <>
        <div id={id} className='invisible'>${dollars}.{centsString}</div>
        $<span className="price"><span className="dollars">{dollars}</span><span className="cents">{centsString}</span></span>
        </>
      )
    }
  
    return (
      <>$<span className="price"><span className="dollars">{dollars}</span><span className="cents">{centsString}</span></span></>
    )
  }
  
  export default Price
  