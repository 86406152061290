
import './App.css';
import React from 'react';
import Category from './components/DisplayList/Category';
import DisplayCategories from './components/DisplayList/DisplayCategories';
import Inventory from './components/Inventory/Inventory';
import IfAuthorized from './components/conditions/IfAuthorized';
import IfNotAuthorized from './components/conditions/IfNotAuthorized';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from './components/auth/AuthUtils';
import { Button } from 'react-bootstrap';
import IdentityTemplate from './components/auth/IdentityTemplate';
import {Route, BrowserRouter as Router, Switch} from "react-router-dom";
import ConfigContext from './components/auth/ConfigContext';
import Header from './components/header/Header';

function App() {
  const { instance, accounts, inProgress } = useMsal();
  const [ config, setConfig ] = React.useState<Record<string, any>>({});

  const handleLogin = async (instance: any) => {
    instance.acquireTokenRedirect(loginRequest).catch((e: any) => {
        console.error(e);
    });
}

  return (
    <div className="App">
      <Router>
        <ConfigContext.Provider value={{ config, setConfig }}>
          <IdentityTemplate>
            <Header></Header>
              {/* <DisplayCategories/> */}
              <IfAuthorized role="editor">
                <Switch>
                  <Route path="/admin/:dbname/item/:id">
                    <Inventory/>
                  </Route>
                  <Route path="/admin/:dbname/item/">
                    <Inventory/>
                  </Route>
                </Switch>
                
              </IfAuthorized>
              <IfNotAuthorized role="editor">
                <div>
                  Forbidden: You are not authorized to access this page
                </div>
                <Button onClick={() => handleLogin(instance)}>Login</Button>
              </IfNotAuthorized>
              {/* <Inventory/> */}
              {/* <Test id={'123456'}/> */}
          </IdentityTemplate>
        </ConfigContext.Provider>
      </Router>
    </div>
  );
}

export default App;
