import React, { Suspense, useContext } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { Link, useHistory, useLocation } from "react-router-dom";
import Select, { SelectChangeEvent } from '@mui/material/Select/Select';
import FormControl from '@mui/material/FormControl/FormControl';
import InputLabel from '@mui/material/InputLabel/InputLabel';
import ConfigContext from '../auth/ConfigContext';
import IdentityContext from '../auth/IdentityContext';
import { Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

type Props = {
  areFiltersOpen?: boolean,
  setAreFiltersOpen?: React.Dispatch<React.SetStateAction<boolean>>,
  deptOpen?: boolean,
  setDeptOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

export default function PrimarySearchAppBar({ deptOpen = false, setDeptOpen, areFiltersOpen = false, setAreFiltersOpen }: Props) {
  const history = useHistory();
  const {config} = useContext(ConfigContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState<null | HTMLElement>(null);
  const root = config.general?.root_category;
  const {identity, setIdentity} = useContext(IdentityContext);
  const location = useLocation();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleFiltersOpen = () => {
    setAreFiltersOpen(!areFiltersOpen);
  }

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleLoginMenu = () => {
    handleMenuClose();

    console.log('login!');
    history.push('/members');
  }

  const handleProfileMenu = () => {
    handleMenuClose();

    history.push('/members');
  }

  const handleLogoutMenu = () => {
    handleMenuClose();

    console.log('logout!');
    history.push('/');
  }

  const fontStyle = {} as React.CSSProperties;
  if (config.general?.fonts?.title) {
    fontStyle['fontFamily'] = config.general.fonts.title;
  }

  const logoStyle = {} as React.CSSProperties;
  if (config.general?.logo?.left) {
    logoStyle['left'] = config.general.logo.left;
    logoStyle['top'] = config.general.logo.top;
    logoStyle['height'] = config.general.logo.height;
  }


  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
          size="large">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className="header-grow">
      <div className="header-logo">
        <Link to='/' className='business-title business-name'>
          <img style={logoStyle} alt={root} src={'https://thequeensflorist.sirv.com/' + root + '/logos/icon-small.png'}/>
        </Link>
      </div>
      <Suspense fallback={<div>Loading...</div>}>
        <AppBar position="static">
          <Toolbar className='header-toolbar'>
            <Button variant="contained" endIcon={<LogoutIcon />} onClick={() => {localStorage.clear(); window.location.reload()}}>
              Logout
            </Button>
            <div className='hamburger'>
              {/* <IfAuthorized role="editor">
                <HamburgerMenu deptOpen={deptOpen} setDeptOpen={setDeptOpen}/>
              </IfAuthorized> */}
            </div>
            <div className="header-title">
            {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
              <InputLabel id="store-selector-label">Store</InputLabel>
              <Select
                  labelId="store-selector-label"
                  id="store-selector"
                  value={store}
                  onChange={handleStoreChange}
                  label="Age"
                >
                  <MenuItem value='' disabled selected>Store Selector</MenuItem>
                  <MenuItem value='backroom'>The Backroom</MenuItem>
                  <MenuItem value='supplies'>Atlantic Grow Shop [Garden Centre]</MenuItem>
                  <MenuItem value='nursery'>Atlantic Grow Shop [Nursery]</MenuItem>
                </Select>
              </FormControl> */}
            </div>
            {/* <SearchField display='desktop'/>
              <IconButton
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                size="large">
                <AccountCircle />
              </IconButton> */}
          </Toolbar>
        </AppBar>
      </Suspense>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
}
