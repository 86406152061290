import { TextField } from '@mui/material';
import React, {useEffect} from 'react';

type Props = {
    value?: number,
    onCommit: (value: number) => void,
    readonly?: boolean,
    label?: string,
    id?: string,
    layout?: string
}

const splitValue = (value: number | undefined): string => {
    const dollars = value ? Math.trunc(value / 100) : 0;
    const cents = value ? value - (dollars * 100) : 0;
    const centsString = cents < 10 ? "0" + cents : cents;
    return dollars + '.' + centsString;
}

const PriceInput = ({ value, onCommit, readonly, label, id, layout }: Props) => {
    const [price, setPrice] = React.useState<string>(!!value ? splitValue(value) : "0.00");

    useEffect(() => {
        setPrice(splitValue(value));
    }, [value]);    


    const onChange = (event: React.FocusEvent<HTMLInputElement>) => {
        const stringValue = event.target.value;
        setPrice(stringValue.replace(/[^\d.]/g, ''));
    }

    const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const stringValue = event.target.value;
        if (stringValue === '') {
            setPrice('');
            return;
        }
        const dvalue = Number(stringValue.replace(".", ""));
        
        setPrice(splitValue(dvalue))
        onCommit(dvalue);
    }

    return (
        <>
            <TextField autoFocus InputLabelProps={{ shrink: true }} id="edit-cost" label={label} value={price} onChange={onChange} onBlur={onBlur}/>
        </>
    )
}

export default PriceInput