import { TextField } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import React from 'react';
import { NumberFormatCustom } from '../../utils/NumberFormatter';

type Props = {
    initial?: number,
    handleCommit: (value: number) => void,
    readonly?: boolean,
    label?: string,
    id?: string,
    layout?: string,
    resetAt?: Date
}

const CountInput = ({ initial, handleCommit, readonly, label, id, layout, resetAt }: Props) => {
    const [remberInitial, setInitial] = React.useState<number | undefined>(initial);
    const [itemCount, setItemCount] = React.useState<string>(initial !== undefined ? String(initial) : '1');

    const resetDateBenchmark = new Date("2023-04-07");
    const useResetAt = typeof resetAt === "string" ? new Date(resetAt) : resetAt;
    const needsResetting = !useResetAt || resetDateBenchmark.getTime() > useResetAt.getTime();
    const resetClass = needsResetting ? "reset-counter" : "";

    // We are synchronizing the local in-process editing state
    // vs. an externally tracked state. This gets funky.
    if (remberInitial !== initial) {
        setInitial(initial);
        setItemCount(initial !== undefined ? String(initial) : '1');
    }

    const incrementCount = (event: React.MouseEvent<HTMLElement>) => {
        let count: number = +itemCount;
        if (count < 0) {
            count = 0;
        }
        count = count + 1;
        setItemCount("" + count);
        handleCommit(count);
    };
    
    const decrementCount = (event: React.MouseEvent<HTMLElement>) => {
        let count: number = +itemCount;
        if (count < 0) {
            count = 0;
        }
        if (count > 0) {
            count = count - 1;
        }

        setItemCount("" + count);
        handleCommit(count);
    }

    const localHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value === undefined || value === '') {
            setItemCount('');
            return;
        }
        const intValue = +value;
        const useValue = intValue >= 0 ? value : '0';
        setItemCount(useValue);
    }    
    
    const commitChange = (event: React.FocusEvent<HTMLInputElement>) => {
        const value = event.target.value ? event.target.value : '0';
        let intValue = +value;
        if (intValue < 0) {
            intValue = 0;
        }
        setItemCount("" + intValue);
        handleCommit(intValue);
    }    
    
    if (readonly) {
        return (<span className='count-input'>{itemCount}</span>)
    }

    return (
        <span className='count-input'>
            <div className='count-input-buttons'>
                <IconButton aria-label="plus one" size="small" onClick={incrementCount}>
                    <AddIcon />
                </IconButton>
                <IconButton aria-label="minus one" size="small" onClick={decrementCount}>
                    <RemoveIcon />
                </IconButton>
            </div>
            <TextField
                className={resetClass + " count-input-value"}
                label={label ? label : ""}
                value={itemCount}
                onChange={localHandleChange}
                onBlur={commitChange}
                name="numberformat"
                id={id ? id : "formatted-numberformat-input"}
                InputLabelProps={{
                    shrink: true,
                }}
                InputProps={{
                    inputComponent: NumberFormatCustom as any,
                }}
            />
        </span>
    )
}

export default CountInput