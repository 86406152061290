import './Header.css';
import React, { useContext } from 'react';
import ConfigContext from '../auth/ConfigContext';
import PrimarySearchAppBar from './PrimarySearchAppBar';

const Header = () => {
  const {config} = useContext(ConfigContext);

  const fontStyle = {} as React.CSSProperties;
  if (config.general?.fonts?.menu) {
    fontStyle['fontFamily'] = config.general.fonts.menu;
    fontStyle['textTransform'] = 'uppercase';
  }

  let bufferClasses=['buffer'];
  let navbarClasses=['navbar'];

  return (
    <>
    <div id="header" className={navbarClasses.join(" ")}>
        <PrimarySearchAppBar/>
    </div>
    <div id='buffer' className={bufferClasses.join(" ")}>

    </div>
    </>
  )
}

export default Header;