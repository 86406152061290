import './EditablePriceField.css';

import { IconButton } from '@mui/material';
import React, {useState} from 'react';
import Price from './Price';
import EditIcon from '@mui/icons-material/Edit';
import PriceInput from './PriceInput';

type Props = {
    value?: number,
    onCommit?: (value: number) => void,
    readonly?: boolean,
    label?: string,
    id?: string,
    layout?: string
}

const EditablePriceField = ({ value, onCommit, readonly, label, id, layout }: Props) => {
    const [editing, setEditing] = useState(false);

    const onClick = () => {
        setEditing(true);
    }

    const doCommit = (value: number) => {
        if (onCommit) {
            onCommit(value);
        }
        setEditing(false);
    }

    if (editing) {
        return (
            <div className='editable-price-field'>
                <PriceInput value={value} onCommit={doCommit} label={label}/>
            </div>
        )
    }
    
    return (
        <div className='editable-price-field'>
            <div className='dctf-label'>
                {label}
            </div>
            <div className='dctf-text'>
                <IconButton aria-label="Edit" size="small" onClick={onClick}>
                    <EditIcon fontSize="small" />
                </IconButton>
                <Price price={value}/>
            </div>
        </div>
    )
}

export default EditablePriceField